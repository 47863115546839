import React, { useCallback, useEffect, useState, FC } from "react";
import { ExperimentProvider, IExperiment } from "@btransport/react-experimentation";
import { DictionaryObject } from "@bookingcom/lingojs-parser";
import { ChatMeta } from "../../../types";
import { ChatFrameContainerProps } from "../ChatFrameContainer";
import { publishMicroConversionClickEvent, publishMicroConversionErrorEvent } from "../../../utils/EventUtils";
import { initialState } from "../../contexts/chatState";
import { getSession } from "../../../utils/LocalStorageUtils";

interface Props {
	chatMeta: ChatMeta;
	etClientsidePayload?: string;
	experiments?: Record<string, IExperiment>;
	lingoTranslations?: DictionaryObject;
	lingoLocale?: string;
}

export const ChatContainer: FC<Props> = ({
	chatMeta,
	etClientsidePayload,
	experiments,
	lingoTranslations,
	lingoLocale
}) => {
	const [chat, setChat] = useState<{
		ChatFrameContainer?: FC<ChatFrameContainerProps>;
	}>({ ChatFrameContainer: null });
	const [isOpen, setIsOpen] = useState(false);
	const [token, setToken] = useState("");

	const importContainer = (): void => {
		import(/* webpackChunkName: "chat-window-css" */ "../ChatFrameContainer")
			.then(({ ChatFrameContainer }) => {
				return setChat({ ChatFrameContainer });
			})
			.catch(error => {
				// eslint-disable-next-line no-console
				console.log(error);
				publishMicroConversionErrorEvent("ChatFrameContainer", "importContainer fail", chatMeta, initialState);
			});
	};

	const handleLaunchClick = useCallback((event: MessageEvent): void => {
		if (event.data === "OPEN_CHAT") {
			publishMicroConversionClickEvent("opened", chatMeta, initialState);
			setIsOpen(true);
			importContainer();
			window.removeEventListener("message", handleLaunchClick);
		}
	}, []);

	useEffect(() => {
		const sessionToken: string = getSession();
		if (sessionToken !== "") {
			setToken(sessionToken);
			const chatSessionTimer = setTimeout(() => {
				importContainer();
			}, 1000);
			return () => {
				clearTimeout(chatSessionTimer);
			};
		}
	}, []);

	useEffect(() => {
		/** this event listener is overwritten in ChatFrameContainer
		 it is needed to load JS bundles only once * */
		window.addEventListener("message", handleLaunchClick);
	}, [handleLaunchClick]);

	return (
		<div data-testid="ChatContainer" className="ChatContainer">
			{chat.ChatFrameContainer && (
				<ExperimentProvider etClientsidePayload={etClientsidePayload} experiments={experiments || {}}>
					<chat.ChatFrameContainer
						chatMeta={chatMeta}
						lingoTranslations={lingoTranslations}
						lingoLocale={lingoLocale}
						isOpen={isOpen}
						token={token}
					/>
				</ExperimentProvider>
			)}
		</div>
	);
};
