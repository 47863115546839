import { registerDynamicTags, t } from "@bookingcom/lingojs-core";
import { EuCountries } from "../cor/corUtils";

// register links
registerDynamicTags(t("gt_mig_tr_footer_links_geoblockingportal"));
registerDynamicTags(t("gt_mig_tr_footer_privacypolicy"));
registerDynamicTags(t("gt_mig_tr_footer_cookies"));
registerDynamicTags(t("gt_mig_tr_footer_termsconditions"));
registerDynamicTags(t("gt_mig_tr_footer_help"));
registerDynamicTags(t("gt_mig_tr_footer_modernslaverystatement"));
registerDynamicTags(t("webcore_shell_footer_human_rights_statement"));
registerDynamicTags(t("gt_mig_tr_howwework_title"));
registerDynamicTags(t("gt_mig_tr_footer_marketplace"));
registerDynamicTags(t("gt_mig_tr_footer_affiliateprogramme"));
registerDynamicTags(t("gt_mig_tr_footer_datasubjectrequest2"));
registerDynamicTags(t("gt_mig_tr_footer_careers"));
registerDynamicTags(t("gt_mig_tr_footer_contactus"));
registerDynamicTags(t("cars_web_rentalcars_footer_link_dsa"));
registerDynamicTags(t("cars_web_rentalcars_footer_link_cg"));

interface Links {
	key: string;
	href?: string;
	onClick?(): void;
	testId: string;
	enabledFor?: (options: { cor: string } | { corSubDivision?: string }) => boolean;
}

export const filter = (links: Array<Links>, cor: string, corSubDivision?: string): Array<Links> | undefined => {
	return (
		cor &&
		links.filter((item) => {
			if (!item.enabledFor) {
				return true;
			}

			return item.enabledFor({ cor, corSubDivision });
		})
	);
};

export const isEnabledForCountry =
	(countries: string[]) =>
	({ cor }) =>
		countries.includes(cor.toUpperCase());

export const isEnabledForCountrySubDivision =
	(countrySubDivisions: string[]) =>
	({ corSubDivision }) => {
		if (!corSubDivision) {
			return false;
		}

		return countrySubDivisions.includes(corSubDivision.toUpperCase());
	};

export const linksGenerator = ({
	language,
	cor,
	corSubDivision,
	showAffiliateLink,
	showCareersLink,
	showSupplyPartnerEnquiryAndMarketplaceLink,
	abuLocale,
}): Array<Links> => {
	if (!language && !cor) {
		// eslint-disable-next-line no-console
		console.warn("WARNING: language and cor not provided");
	}

	const links: Array<Links> = [
		{
			key: "gt_mig_tr_footer_privacypolicy",
			href: `/PrivacyPolicy.do`,
			testId: "privacyPolicy",
		},
		{
			key: "gt_mig_tr_footer_cookies",
			onClick: () => {
				window?.Optanon?.ToggleInfoDisplay();
			},
			testId: "cookiePolicy",
		},
		{
			key: "gt_mig_tr_footer_termsconditions",
			href: `/${language}/terms-and-conditions`,
			testId: "generalTermsLink",
		},
		{
			key: "gt_mig_tr_footer_help",
			href: `/help`,
			testId: "helpLink",
		},
		{
			key: "gt_mig_tr_footer_modernslaverystatement",
			href: "https://www.bookingholdings.com/modern-slavery-statement/",
			testId: "slaveryStatement",
		},
		{
			key: "webcore_shell_footer_human_rights_statement",
			href: "https://www.bookingholdings.com/about/compliance-and-ethics/human-rights/",
			testId: "humanRightsStatement",
		},
		{
			key: "gt_mig_tr_howwework_title",
			href: `/${language}/how-we-work`,
			testId: "howWeWork",
		},
		{
			key: "gt_mig_tr_footer_marketplace",
			href: `https://marketplace.rentalcars.com/#/find-out-more`,
			testId: "marketplaceLink",
			enabledFor: () => showSupplyPartnerEnquiryAndMarketplaceLink,
		},
		{
			key: "gt_mig_tr_footer_affiliateprogramme",
			href: "https://www.rentalcarsconnect.com/",
			testId: "affiliateProgramLink",
			enabledFor: () => showAffiliateLink,
		},
		{
			key: "gt_mig_tr_footer_datasubjectrequest2",
			href: `/DataSubjectRequestPortal.do`,
			testId: "dataSubjectRequestLink",
			enabledFor: isEnabledForCountrySubDivision(["CALIFORNIA"]),
		},
		{
			key: "gt_mig_tr_footer_careers",
			href: "https://careers.booking.com/",
			testId: "careersLink",
			enabledFor: () => showCareersLink,
		},
		{
			key: "gt_mig_tr_footer_contactus",
			href: `/${language}/contact`,
			testId: "contactUsLink",
		},
		{
			key: "cars_web_rentalcars_footer_link_dsa",
			href: `https://www.booking.com/content/dsa.${abuLocale}.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARjIAQzYAQHoAQH4AQyIAgGoAgS4Avy9pLcGwAIB0gIkMDYwOWY0YjQtYTkwYy00YzYyLTlmYjYtMWI2OWQyNDAwODEw2AIG4AIB&sid=0b48cfa9056cbcd8bdb994159b772ae5`,
			testId: "dsaLink",
			enabledFor: isEnabledForCountry(EuCountries),
		},
		{
			key: "cars_web_rentalcars_footer_link_cg",
			href: `https://www.booking.com/content-moderation-policy/overview-page.${abuLocale}.html?aid=304142&label=gen173nr-1FCAEoggI46AdIM1gEaGyIAQGYAQm4ARjIAQzYAQHoAQH4AQyIAgGoAgS4Avy9pLcGwAIB0gIkMDYwOWY0YjQtYTkwYy00YzYyLTlmYjYtMWI2OWQyNDAwODEw2AIG4AIB&sid=26ec7d1b3082257f85e9da7938e1a5d3`,
			testId: "contentModerationPolicyLink",
			enabledFor: isEnabledForCountry(EuCountries),
		},
	];

	return filter(links, cor, corSubDivision);
};
