/* istanbul ignore file */
import {
	ChatMeta,
	ChatState,
	EventInfo,
	ParsedDefaultValuesFromChatState,
	ParsedAdditionalEventInfo,
	AdditionalEventInfo,
	ParsedDefaultValuesFromChatMeta
} from "../types";

const extractDefaultValuesFromChatState = (chatState: ChatState): ParsedDefaultValuesFromChatState => {
	return {
		queue: chatState.selectedQueueName,
		email: !!chatState.driverDetails?.email,
		bookingReference: chatState.driverDetails?.bookingReference ? chatState.driverDetails.bookingReference : ""
	};
};

const extraDefaultValuesFromAdditionalEventInfo = (
	additionalEventInfo: AdditionalEventInfo
): ParsedAdditionalEventInfo => {
	if (!additionalEventInfo) {
		return {};
	}
	let info: ParsedAdditionalEventInfo = {};
	if (additionalEventInfo.csat) {
		info = {
			csat: {
				csatScore: additionalEventInfo.csat.csatScore,
				resolved: additionalEventInfo.csat.resolved,
				emailTranscript: additionalEventInfo.csat.emailTranscript,
				comments: additionalEventInfo.csat.comments
			}
		};
	}
	if (additionalEventInfo.email) {
		info.email = !!additionalEventInfo.email;
	}
	if (additionalEventInfo.bookingReference) {
		info.bookingReference = additionalEventInfo.bookingReference || "";
	}
	return info;
};

const extractDefaultValuesFromChatMeta = (chatMeta: ChatMeta): ParsedDefaultValuesFromChatMeta => {
	return {
		language: chatMeta.language,
		country: chatMeta.country,
		locale: chatMeta.locale,
		affiliateCode: chatMeta.affiliateCode,
		brand: chatMeta.brand,
		apiEnabled: chatMeta.apiEnabled
	};
};

const getPageName = (): {
	page: string;
} => {
	return {
		page: window.location.pathname
	};
};

const populateAdditionalInfo = (
	chatMeta: ChatMeta,
	chatState: ChatState,
	additionalEventInfo?: AdditionalEventInfo
): EventInfo => {
	return {
		...extractDefaultValuesFromChatMeta(chatMeta),
		...extractDefaultValuesFromChatState(chatState),
		...extraDefaultValuesFromAdditionalEventInfo(additionalEventInfo),
		...getPageName()
	};
};

export const publishMicroConversionClickEvent = (
	label: string,
	chatMeta: ChatMeta,
	chatState: ChatState,
	additionalEventInfo?: AdditionalEventInfo
): void => {
	if (window.publishMicroConversionEvent) {
		window.publishMicroConversionEvent(
			"live_chat",
			"click",
			label,
			populateAdditionalInfo(chatMeta, chatState, additionalEventInfo)
		);
	}
};

export const publishMicroConversionViewEvent = (
	label: string,
	chatMeta: ChatMeta,
	chatState: ChatState,
	additionalEventInfo?: AdditionalEventInfo
): void => {
	if (window.publishMicroConversionEvent) {
		window.publishMicroConversionEvent(
			"live_chat",
			"view",
			label,
			populateAdditionalInfo(chatMeta, chatState, additionalEventInfo)
		);
	}
};

export const publishMicroConversionErrorEvent = (
	action: string,
	label: string,
	chatMeta: ChatMeta,
	chatState: ChatState,
	additionalEventInfo?: AdditionalEventInfo
): void => {
	if (window.publishMicroConversionEvent) {
		window.publishMicroConversionEvent(
			"live_chat_error",
			action,
			label,
			populateAdditionalInfo(chatMeta, chatState, additionalEventInfo)
		);
	}
};
